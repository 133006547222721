*,
::after,
::before {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-size: 1em;
}

html {
    font-size: 62.5%;
    background-size: cover;
}

body {
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    height: 100%;
}

.Toastify__toast {
    border-radius: 0.4rem;
}

.Toastify__toast-body {
    font-size: 1.4rem;
    font-weight: 600;
}

/* CUSTOM MATERIAL UI */

.MuiTypography-subtitle1 {
    font-size: 1.6rem !important;
}

.MuiTypography-h4 {
    font-size: 3.4rem !important;
}

.MuiTypography-body1 {
    font-size: 1.6rem !important;
}

.MuiTypography-caption {
    font-size: 1.2rem !important;
}

.MuiTypography-body2 {
    font-size: 1.4rem !important;
}

.MuiTooltip-tooltip {
    font-size: 1.2rem !important;
}

.MuiButton-root {
    font-size: 1.4rem !important;
}

.MuiSvgIcon-root {
    font-size: 2.4rem !important;
}
